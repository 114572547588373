import { InvestorInformation } from "./startup-investor-dashboard.model";
import { IdNameOptions } from "./startup.model";

export interface InvididualProfileModel {
  id: number
  uuid: string
  status: boolean
  createdAt: string
  modifiedAt: string
  deletedAt: any
  userId: number
  name: string
  avatar: any
  registeredCountryId: any
  registeredStateId: any
  registeredCityId: any
  linkedinUrl: any
  twitterUrl: any
  facebookUrl: any
  profileCompletenessPercent: number
  shortDescription: any
  referralCode: any
  domain: string
  shortProfileLink: string
  isRejected: boolean
  isApproved: boolean
  registeredCountryR: any
  registeredStateR: any
  registeredCityR: any

}


export interface InvididualProfileResponseModel {
  data: InvididualProfileModel
  message: string;
  status_code: number;
}


export interface InvididualProfileCompleteness {
  total: number;
  completed: number;
  percentage: number;
  canRequestApproval: boolean;
  canToggleStatus: boolean;
  isApprovalRequested: boolean;
  isProvidingFundings: boolean;
  forms: any;
  isRejected: boolean;
  isApproved: boolean
  approvalStatus: string
}

// export interface IMentorPublicProfile {
//   uuid: string
//   name: string
//   avatar: any
//   designation: any
//   registeredCountry: string
//   registeredCity: string
//   linkedinUrl: any
//   websiteUrl: any
//   twitterUrl: any
//   domainAreas: DomainArea[]
//   technologies: Technology[]
//   sectoralInterestIds: SectoralInterestId[]
//   briefDescription: string
//   shortDescription: any
//   user: User[]


//   isApproved: boolean
//   currentOrganization: string
//   featured: boolean
//   registeredCountryR: IdNameOptions;
//   registeredStateR: IdNameOptions;
//   registeredCityR: IdNameOptions;
//   // programName: string
//   // totalSupported: number
//   // connectionRequirements: string
//   modifiedAt: string
// }

// interface DomainArea {
//   id: number
//   name: string
//   isActive: boolean
// }

// interface Technology {
//   id: number
//   name: string
//   isActive: boolean
// }

// interface SectoralInterestId {
//   id: number
//   name: string
//   isActive: boolean
// }

// interface User {
//   uuid: string
//   accountRole: string
//   name: string
// }

import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { COUNTRY_CODES_ARR } from 'src/app/shared/constants/country-code';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, finalize, Observable, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/service';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { select, Store } from '@ngrx/store';
import { getPending } from 'src/app/core/state/auth';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ACCOUNT_TYPE, Glossary } from 'src/app/core/domain';
import { IProgram } from 'src/app/core/domain/program.model';
import { PublicApiService } from 'src/app/core/service/public-api.service';
import { Router } from '@angular/router';
import * as NotificationsActions from "../../../core/state/notifications/notifications.action";
import { IIpManagement } from 'src/app/core/domain/ip-management.model';

@Component({
  selector: 'app-ip-details-modal',
  templateUrl: './ip-details-modal.component.html',
  styleUrls: ['./ip-details-modal.component.scss']
})
export class IpDetailsModalComponent implements OnInit {

  @Input('modalRef') modalRef: NgbModalRef;
  @Input('ip') ip: IIpManagement;
  @Input() applied: boolean;
  public profileDetails$: Observable<IProfileDetails>;
  profileDetails: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();

  loader = false;
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private toastAlertService: ToastAlertService,
    private readonly store: Store,
    private modalService: NgbModal,
    private spinner: NgxUiLoaderService,
    private publicApi: PublicApiService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileDetails = res;
      }
    })
  }

  applyForProgram() {
    // if (this.applied) {
    //   console.log('applied -->', this.applied, this.ip.uuid)
    //   this.closeModal()
    //   setTimeout(() => {
    //     this.router.navigateByUrl(`/programs/applied/${this.ip.uuid}`)
    //   })
    //   return
    // }
    // if (this.profileDetails) {
    //   this.loader = true;
    //   this.publicApi.applyForProgram(this.ip.uuid).subscribe((res) => {
    //     this.store.dispatch(new NotificationsActions.SetNotificationsCount());
    //     this.loader = false;
    //     this.closeModal()
    //     this.router.navigateByUrl(`/programs/applied/${this.ip.uuid}`)
    //   }, err => {
    //     this.loader = false;
    //   })
    // } else {
    //   if (this.ip.source === 'external') {
    //     window.open(this.ip.registrationLink, '_blank');
    //     return
    //   }
    //   localStorage.setItem('urlBeforeLoginIn', `/programs?code=${this.ip.programCode}&fromLogin=true`)
    //   localStorage.setItem('redirect-after-register-to', `/programs?code=${this.ip.programCode}&fromRegister=true`)
    //   // localStorage.setItem('redirect-after-register-to', `/programs?code=${this.program.programCode}&fromRegister=true`)
    //   // localStorage.setItem('urlBeforeLoginIn', `/programs?code=${this.program.programCode}`)
    //   this.closeModal()
    //   setTimeout(() => {
    //     this.router.navigateByUrl(`/auth/login?program=${this.ip.programCode}&userType=${ACCOUNT_TYPE.STARTUP}`)
    //   })
    // }
  }
  // get hasForms() {
  //   return this.ip?.formId?.length
  // }



  closeModal(): void {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}


<div class="card image-container shadow-sm" *ngIf="profileData && globalSettings">
  <div class="position-absolute cursor-pointer" style="top: 5px; right: 5px;" (click)="closeModal()">
    <i class="bi bi-x fs-2hx" ></i>
  </div>

    <div class="" *ngIf="!profileCompleteness?.isApproved">
      <div class="after rounded">
        <div class="d-flex flex-column align-items-center">
          <p class="text-white fs-2 fw-bold">
            Only approved users can post.
          </p>

          <!-- <button class="btn btn-theme text-white text-uppercase fw-boldest fs-6  btn-sm w-fit-content ">
            <span class="indicator-label">Upgrade Plan</span>
          </button> -->
        </div>
      </div>

  </div>

  <div class="card-body pb-0 px-5 pt-5">

    <div class="d-flex align-items-start">
      <!-- <div class="d-flex align-items-center flex-grow-1">
        <div class="symbol symbol-45px me-5 p-1 border rounded">
          <img *ngIf="profileData.avatar" [src]="(profileData?.avatar | imagekitUrlAppender) + '?tr=w-150,h-150,cm-pad_resize'" alt="" />


          <ngx-avatars bgColor="#eaeaea" *ngIf="!profileData.avatar" [name]="profileData?.name" cornerRadius="4"
            size="50" [round]="false">
          </ngx-avatars>

        </div>
      </div> -->

      <div class="w-100">
        <div id="kt_forms_widget_1_form" class="ql-quil ql-quil-plain feed-form pb-2  mb-4">
          <quill-editor id="floatingTextarea-community-feed"
            [styles]="{height: 'auto', 'min-height': '200px','padding' : '7px'} "
            [disabled]="!profileCompleteness?.isApproved" [(ngModel)]="postData.text"
            placeholder="What’s in your mind today?" [modules]="quillModules"></quill-editor>
        </div>

        <div class="d-flex flex-wrap">
          <ng-container  *ngIf="imageSrc.length">
          <div class="position-relative w-fit me-3"  *ngFor="let imgSrc of imageSrc;let i = index">
              <div
                class="btn-red p-3 cursor-pointer text-white d-flex justify-content-center align-items-center position-absolute"
                style="height: 10px; width: 10px; border-radius: 50%;
              top: -3px;right: -11px;" (click)="clearImageSelection(i)">
                x
              </div>
              <img height="100px" class="my-4" id="blah" [src]="imgSrc" alt="your image" />
            </div>
          </ng-container>
        </div>

        <div class="position-relative w-fit" *ngIf="selectedFile">
          <div
            class="btn-red p-3 cursor-pointer text-white d-flex justify-content-center align-items-center position-absolute"
            style="height: 10px; width: 10px; border-radius: 50%;top: -3px;right: -11px;"
            (click)="clearFileSelection()">
            x
          </div>

          <div class="my-4 ">
            <div class="d-flex align-items-center">
              <div class="fi fi-{{fileExtension ? fileExtension : 'any'}} m-0">
                <div class="fi-content">{{fileExtension ? fileExtension : 'any'}}
                </div>
              </div>
              <span class="ms-4">
                {{selectedFile.name}}
              </span>
            </div>
          </div>
        </div>




        <div class="d-flex justify-content-start mb-4 align-items-center">
          <div ngbTooltip="Only approved users can post." [disableTooltip]="profileCompleteness?.isApproved">
            <button class="btn  ls-2 text-uppercase btn-sm fs-6 text-end fw-boldest"
              [ngClass]="postData.text ? 'btn-primary text-white' : 'btn-secondary text-black'"
              [disabled]="!postData.text" (click)="handleSubmit()">
              Post
            </button>
          </div>

          <div class="d-flex align-items-center" ngbTooltip="Attach Image">
            <div (click)="handleSelectImage()" class="d-flex align-items-center cursor-pointer ms-5 fs-1">
              <i class="bi bi-image fs-1 me-2 header-icons-font-size"></i>
            </div>
            <!-- <div (click)="handleSelectFile()" class="d-flex align-items-center ms-5 cursor-pointer  btn btn-light btn-sm">
              <i class="bi bi-files fs-5 me-2 header-icons-font-size"></i>
              Select File
            </div> -->
          </div>

        </div>
      </div>

    </div>





  </div>

</div>

<div class="row g-6 g-xl-9 mb-8" *ngIf="investors?.length">

  <!-- <ngb-carousel [showNavigationArrows]="true" [showNavigationIndicators]="true" [interval]="3000">
        <ng-template ngbSlide *ngFor="let investors of chunkedInvestors">
            <div class="row g-6 g-xl-9">

                <div class="col-6 col-md-3 col-xxl-3" *ngFor="let investor of investors">
                    <app-startup-search-card [startup]="investor"></app-startup-search-card>
                </div>
            </div>

        </ng-template>

    </ngb-carousel> -->
    <div class="d-flex flex-wrap">
      <span class="circle"></span>
      <h1 class="fs-1hx text-dark mb-5  ms-6">Recommended Mentors</h1>
    </div>


  <swiper #swiper  [virtual]="true" [spaceBetween]="30" [navigation]="true" [autoplay]="true" [pagination]="{
        clickable: true
      }" class="recommended-investor-slider" (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()" [breakpoints]="{
          '320': {
              slidesPerView: 2,
              spaceBetween: 20
          },
          '640': {
            slidesPerView: 2,
            spaceBetween: 20
          },
          '768': {
            slidesPerView: 3,
            spaceBetween: 30
          },
          '1024': {
            slidesPerView: 4,
            spaceBetween: 30
          }
        }">


    <ng-template swiperSlide *ngFor="let investor of investors">
      <!-- <app-startup-search-card [avtarSize]="'small'" [disableHover]="true" [isShowCompareButton]="false"
      [openedFrom]="'recommended'" (hideRecommendedStartup)="hideStartup($event)"
        [startup]="investor"></app-startup-search-card> -->
        <app-mentor-search-card [corporate]="investor">

        </app-mentor-search-card>
    </ng-template>
  </swiper>



</div>

<div class="min-w-230 min-w-md-400  mt-md-0" *ngIf="!hideSearchBar">

  <ng-template #rt let-r="result" let-t="term">

    <div class="d-flex align-items-center w-100 my-2" *ngIf="!r.viewAll">
      <img [src]="r?.['logo']" class="me-1" style="width: 20px" *ngIf="r?.logo">
      <div class="d-flex" *ngIf="!r.viewAll && (r.searchType || r.accountType)">
        <div class="mx-4">{{r.name}}</div>
        <span class="badge badge-secondary bg-light text-gray-600 px-3 py-2 me-2">
          {{ r.searchType || accountTypelabelMap[r.accountType] || r.accountType}}
        </span>
      </div>
    </div>

    <div class="d-flex" *ngIf="r.viewAll">
        <b>{{r.name}}</b>
    </div>


  </ng-template>

  <div class="row">
    <div class="col">
      <input id="typeahead-template" type="text" class="form-control col" [(ngModel)]="model"
        (keydown)="handleKeyPress($event)" [placeholder]="searchPlaceHolder" [ngbTypeahead]="search"
        [resultTemplate]="rt" [inputFormatter]="formatter" (selectItem)="onSelectItem($event)" />
    </div>
  </div>

</div>

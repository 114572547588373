import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, finalize, Observable, Subject, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { RecommendedService } from 'src/app/core/service/recommended.service';
import { getProfileData } from 'src/app/core/state/profile';
import Swal from 'sweetalert2';


// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation, SwiperOptions, Virtual, Pagination } from "swiper";
import { SwiperComponent } from 'swiper/angular';
// install Swiper modules
SwiperCore.use([Autoplay, Navigation, Virtual, Pagination]);

@Component({
  selector: 'app-recommended-startups',
  templateUrl: './recommended-startups.component.html',
  styleUrls: ['./recommended-startups.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecommendedStartupsComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  private destroyed$: Subject<void> = new Subject();
  loading = false;
  investors = []
  profileDetails: IProfileDetails;
  public profileDetails$: Observable<IProfileDetails>;
  // chunkedInvestors = []

  constructor(
    private recommendedService: RecommendedService,
    private readonly store: Store,
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.profileDetails$ = this.store.pipe(select(getProfileData));
    this.profileDetails$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileDetails = res;
        this.getStartups();
      }
    })

  }

  onMouseenter(){
    console.log('onMouseenter', this.swiper.swiperRef)
    this.swiper.swiperRef.autoplay.stop()
  }

  onMouseleave() {
    this.swiper.swiperRef.autoplay.start()
  }
  getStartups() {
    this.recommendedService.getRecommendedStartups(this.profileDetails.accountType).pipe(finalize(() => this.loading = false)).subscribe((res) => {
      console.log('getRecommendedStartups', res)
      if (res && res.length) {
        this.investors = res.map((e) => {
          return {
            ...e,
            user: e.user[0],
            elevatorPitch: e.pitchDeck.elevatorPitch
          }
        })
      }
    })
  }

  hideStartup(id: string) {
    Swal.fire({
      title: 'Hide Recommendation',
      text: 'Are you sure want to remove this recommendation. The result will not appear in recommendations in future.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-theme)',
      cancelButtonColor: '#7D8185',
      confirmButtonText: 'Hide'
    }).then((result) => {
      if (result.isConfirmed) {
        this.recommendedService.hideRecommendedStartups(id).subscribe((res) => {
          if (res) {
            console.log('hideStartup');
            this.getStartups();
          }
        })
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

<div class="modal-content">
  <ngx-ui-loader></ngx-ui-loader>
  <form *ngIf="meetingForm" [formGroup]="meetingForm" (ngSubmit)="onSubmit()"
    class="form fv-plugins-bootstrap5 fv-plugins-framework" id="kt_modal_add_event_form">
    <div class="modal-header">
      <h2 class="fw-bolder" data-kt-calendar="title">Schedule Meeting</h2>
      <div class="btn btn-icon btn-sm btn-active-icon-primary" id="kt_modal_add_event_close">
        <span class="svg-icon svg-icon-1" (click)="close()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)"
              fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor">
            </rect>
          </svg>
        </span>
      </div>
    </div>
    <div class="modal-body py-10 px-lg-17">
      <ng-container *ngIf="calenderAvilablity?.availabilityHours !== METTING_AVAILABILITY.temporary_unavailable">



        <div class="col-12" *ngIf="selectedMeetingWithUser || !modalData.otherUser">
          <div class="fv-row mb-9">
            <label class="fs-6 fw-bold mb-2">Who would you prefer to schedule a meeting with?</label>

            <ng-select [items]="reviewers" [multiple]="false" placeholder="Select a name"
              [ngModelOptions]="{standalone: true}" [clearable]="false" (change)="handleMeetingWithChange($event)"
              [(ngModel)]="selectedMeetingWithUser" [bindLabel]="'label'" class="meeting-time-select" appendTo="body">
              <!-- <ng-template ng-label-tmp let-item="item">
                <b>{{item.label}}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <b>{{item.label}}</b>
              </ng-template> -->



              <ng-template ng-label-tmp let-item="item">

                <p class="mb-0">{{item.label}} ({{ accountTypelabelMap[item?.accountType] || item.accountType }})</p>



              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="d-flex align-items-center">
                  <p class="mb-0">{{item.label}}</p>


                  <div class="badge badge-light bg-white text-uppercase fw-bolder ms-2 d-none d-md-inline-block">
                    {{ accountTypelabelMap[item?.accountType] || item.accountType }}
                  </div>
                </div>

              </ng-template>


            </ng-select>
          </div>
        </div>



        <div class="fv-row mb-9 fv-plugins-icon-container">
          <label class="fs-6 fw-bold required mb-2">Meeting Title</label>
          <input type="text" formControlName="meetingTitle" class="form-control form-control-solid" placeholder=""
            name="calendar_event_name" />
          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div>

        <div class="fv-row mb-9 fv-plugins-icon-container">
          <label class="fs-6 fw-bold mb-2">Agenda</label>
          <textarea formControlName="meetingDescription" class="form-control form-control-solid" placeholder=""
            name="calendar_event_name"></textarea>

          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div>

        <div class="row mb-4 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">

          <div class="col-6">
            <label class="fs-6 fw-bold mb-2 required">Date</label>

            <input class="form-control" formControlName="date" class="form-control form-control-solid flatpickr-input"
              placeholder="yyyy-mm-dd" name="dp" [(ngModel)]="model" ngbDatepicker #d="ngbDatepicker"
              (click)="d.toggle()" [minDate]="minDate" [markDisabled]="isDisabled" />

            <div class="fv-plugins-message-container invalid-feedback"></div>
          </div>


          <!-- <div class="col-6">

            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold mb-2">Start Time</label>
              <input formControlName="timeFrom" class="form-control form-control-solid flatpickr-input"
                name="calendar_event_start_time" placeholder="Pick a start time" id="kt_calendar_datepicker_start_time"
                type="time" (ngModelChange)="handleDurationChange()">
            </div>
          </div> -->


          <div class="col-6">
            <div class="fv-row mb-9">
              <label class="fs-6 fw-bold mb-2">Start Time</label>

              <!-- <select formControlName="timeFrom" class="form-control form-control-solid"
                            (ngModelChange)="handleDurationChange()">
                            <option value="">Select time</option>
                            <option *ngFor="let t of timeSlots" value="{{t}}">{{t | twentyFourHoursToAmPm}}</option>
                          </select> -->


              <ng-select [items]="timeSlots" formControlName="timeFrom" [multiple]="false" placeholder="Select Time"
                (change)="handleDurationChange()" [bindLabel]="'label'" [bindValue]="'value'"
                class="meeting-time-select" appendTo="body">
                <ng-template ng-label-tmp let-item="item">
                  <b>{{item.label}}</b>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <b>{{item.label}}</b>
                </ng-template>
              </ng-select>
            </div>
          </div>


        </div>


        <div class="fv-row mb-5 fv-plugins-icon-container">
          <label class="fs-6 fw-bold required mb-2">Duration</label>
          <select formControlName="duration" class="form-control form-control-solid"
            (ngModelChange)="handleDurationChange()">
            <option value="">Select duration</option>
            <option value="30">30 minutes</option>
            <option value="45">45 minutes</option>
            <option value="60">60 minutes</option>
          </select>
          <!-- <input type="text" formControlName="duration" class="form-control form-control-solid" placeholder=""
          name="calendar_event_name" (ngModelChange)="handleDurationChange($event)" /> -->
          <div class="fv-plugins-message-container invalid-feedback"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="calenderAvilablity?.availabilityHours === METTING_AVAILABILITY.temporary_unavailable">
        <p class="text-center">
          The other party has temporary turned off meetings in their account.
        </p>
      </ng-container>

    </div>
    <div class="modal-footer flex-center">
      <button type="reset" id="kt_modal_add_event_cancel" (click)="close()"
        class="btn btn-light ls-2 text-uppercase fw-boldest me-3">
        Cancel
      </button>
      <ng-container *ngIf="calenderAvilablity?.availabilityHours !== METTING_AVAILABILITY.temporary_unavailable">
        <button type="submit" [disabled]="meetingForm.invalid" id="kt_modal_add_event_submit"
          class="btn btn-theme text-white ls-2 text-uppercase fw-boldest">
          <span class="indicator-label">Submit</span>
          <span class="indicator-progress">Please wait...
            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
      </ng-container>
    </div>
    <div></div>
  </form>
</div>

import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  ReplaySubject,
  Subject,
  throwError
} from "rxjs";
import {
  catchError, distinctUntilChanged, map, takeUntil,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IElevatorPitch, ISupportingDocuments } from "../domain/pitch-deck.model";
import { IProfileDetails, IProfileType } from "../domain/profile.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import { AuthService } from "./auth.service";
import { Store, select } from "@ngrx/store";
import { ACCOUNT_TYPE, CorporateProfileCompleteness, InvestorProfileCompleteness, InvestorTypes, MentorProfileCompleteness, OrganizationInformationResponseModel, ProgramOfficeProfileCompleteness, ServiceProviderProfileCompleteness, StartupProfileCompleteness } from "../domain";
import { getCorporateProfileCompleteness } from "../state/corporate-dashboard/corporate.dashboard.selectors";
import { getMentorProfileCompleteness } from "../state/mentors-dashboard/mentors.dashboard.selectors";
import { getServiceProviderProfileCompleteness } from "../state/service-provider-dashboard/service-provider.dashboard.selectors";
import { getStartupProfileCompleteness } from "../state/startup-dashboard";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness } from 'src/app/core/state/investor-dashboard';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import * as CorporateDashBoardActions from "src/app/core/state/corporate-dashboard/corporate.dashboard.action";
import * as MentorDashBoardActions from "src/app/core/state/mentors-dashboard/mentors.dashboard.action";
import * as ServiceProviderDashBoardActions from "src/app/core/state/service-provider-dashboard/service-provider.dashboard.action";
import * as ProgramOfficeDashBoardActions from "src/app/core/state/program-office-dashboard/program-office.dashboard.action";
import * as IndividualDashBoardActions from "src/app/core/state/individual-profile-dashboard/individual-profile.dashboard.action";
import * as PartnerDashBoardActions from "src/app/core/state/partner-dashboard/partner.dashboard.action";

import { getProfileData } from "../state/profile";
import { getBrandDetails } from "../state/global";
import { IBrandDetails } from "../domain/brand.model";
import { createSlug } from "src/app/shared/utils/common-methods";
import { getInvestorsOrg } from "../state/investors-organization-info";
import { getStartUpInfo } from "../state/startup";
import { getCorporateInfo } from "../state/corporate-info";
import { getMentorInfo } from "../state/mentor-info";
import { getServiceProviderInfo } from "../state/service-provider-info";
import { getProgramOfficeProfileCompleteness } from "../state/program-office-dashboard/program-office.dashboard.selectors";
import { getProgramOfficeInfo } from "../state/program-office-members-info";
import { getIndividualProfileCompleteness } from "../state/individual-profile-dashboard/individual-profile.dashboard.selectors";
import { getIndividualProfileInfo } from "../state/individual-profile-info";
import { getPartnerProfileCompleteness } from "../state/partner-dashboard/partner.dashboard.selectors";
import { getPartnerInfo } from "../state/partner-info";

@Injectable({
  providedIn: "root"
})
export class ProfileService {
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  profileCompleteness$: ReplaySubject<StartupProfileCompleteness | MentorProfileCompleteness | InvestorProfileCompleteness | CorporateProfileCompleteness | ServiceProviderProfileCompleteness | ProgramOfficeProfileCompleteness> = new ReplaySubject();
  profileCompleteness: StartupProfileCompleteness | MentorProfileCompleteness | InvestorProfileCompleteness | CorporateProfileCompleteness | ServiceProviderProfileCompleteness | ProgramOfficeProfileCompleteness;
  brandDetails: Partial<IBrandDetails>;
  profilePageUrl$= new ReplaySubject();
  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private authService: AuthService, private store: Store) {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged()).subscribe((res) => {
      this.profileData = res;
      if(res){
          this.getProfileCompleteness()
      }
    })

    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

    this.profileCompleteness$.subscribe((res)=>{
      if(res){
        this.profileCompleteness = res
        localStorage.setItem('profile-approval-status',this.profileCompleteness.approvalStatus)
      }
    })
  }

  public getProfileDetails(): Observable<IProfileDetails> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getProfileDetails GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`getProfileDetails Fault( ${fault.error.message} )`);

        if(fault.status === 404)  {
          this.authService.logout()
          this.toastService.showToast('Session expired! You have been logged out.', 'error');
        }else{
          this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching profile!'), 'error');
        }

        return throwError(() => fault);
      })
    );
  }


  public getProfileTypes(): Observable<IProfileType[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE_TYPES);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getProfileDetails GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  public switchProfileTypes(type: ACCOUNT_TYPE): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE_TYPES) + '/switch';
    return this.http.post(url, {
      profileType: type
    }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  public createProfileTypes(payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE_TYPES);
    return this.http.post(url, payload).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  get isProfileLocked() {
    if(!this.brandDetails?.features?.profiles_locked) {
      return false
    }
    return !this.profileCompleteness?.isApproved
  }



  get isLimitedAccess() {
    return this.profileCompleteness?.isRejected && this.brandDetails?.features?.limited_access && this.profileCompleteness?.approvalStatus === 'limited_access'
  }

  getProfileCompleteness() {
    if(!this.profileData) {
      return
    }
    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), ).subscribe(res => {
          if (res) {
            this.profileCompleteness$.next(res)
          }
        })
        this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
      } else {
        this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), ).subscribe(res => {
          if (res) {
            this.profileCompleteness$.next(res)
          }
        })
        this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
      }


      this.store.pipe(select(getInvestorsOrg)).subscribe((response: OrganizationInformationResponseModel) => {
        if (response) {
          let profilePageUrl = response.data.shortProfileLink || `/investors/profile/` + `${response.data.uuid}/${createSlug(response.data.organizationName) || '-'}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.pipe(select(getStartupProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
          // console.log({ res })
        }
      });
      this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());

      const startupInfo$ = this.store.pipe(select(getStartUpInfo));
      startupInfo$.pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          let profilePageUrl = res.shortProfileLink || `/startups/profile/` + `${res.uuid}/${createSlug(res.companyName)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.pipe(select(getCorporateProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new CorporateDashBoardActions.GetProfileCompleteness());


      this.store.pipe(select(getCorporateInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          let profilePageUrl = res.data.shortProfileLink || `/corporates/profile/` + `${res.data.uuid}/${createSlug(res.data.companyName)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });

    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.pipe(select(getMentorProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new MentorDashBoardActions.GetProfileCompleteness());


      this.store.pipe(select(getMentorInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
         let profilePageUrl = res.data.shortProfileLink || `/mentors/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.pipe(select(getProgramOfficeProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new ProgramOfficeDashBoardActions.GetProfileCompleteness());

      this.store.pipe(select(getProgramOfficeInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
         let profilePageUrl = res.data.shortProfileLink || `/program-office-members/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new ServiceProviderDashBoardActions.GetProfileCompleteness());

      this.store.pipe(select(getServiceProviderInfo)).pipe(distinctUntilChanged()).subscribe((res: any) => {
        if (res) {
          let profilePageUrl = res.data.shortProfileLink || `/service-provider/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.INDIVIDUAL) {
      this.store.pipe(select(getIndividualProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new IndividualDashBoardActions.GetProfileCompleteness());

      this.store.pipe(select(getIndividualProfileInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          let profilePageUrl = `individuals/edit/individual-information`
        //  let profilePageUrl = res.data.shortProfileLink || `/program-office-members/profile/` + `${res.data.uuid}/${createSlug(res.data.name)}`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.PARTNER) {
      this.store.pipe(select(getPartnerProfileCompleteness)).pipe().subscribe((res: any) => {
        if (res) {
          this.profileCompleteness$.next(res)
        }
      });
      this.store.dispatch(new PartnerDashBoardActions.GetProfileCompleteness());

      this.store.pipe(select(getPartnerInfo)).pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          let profilePageUrl = `partners/edit/partner-information`
          this.profilePageUrl$.next(profilePageUrl)
        }
      });
    }

  }


  public getUserPublicProfile(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PUBLIC_PROFILE) + '/'+ id;
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getProfileDetails GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching profile!'), 'error');
        console.warn(`getUserPublicProfile Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public updateProfileDetails(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE);
    console.info(`updateProfileDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateProfileDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updateNewsLetter(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/subscribe-to-newsletter';
    console.info(`updateProfileDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateProfileDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public updateSocialDetails(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/social-links';
    console.info(`updateSocialDetails info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateSocialDetails Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating profile!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updateNotification(payload: Partial<IProfileDetails>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_PROFILE) + '/notification-settings';
    console.info(`updateNotification info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateNotification Info failed( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating notification!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public uploadProfilePhoto(logo: File): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_AVTAR);
    console.info(`saving profile photo`, payload);
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading profile photo'), 'error');
        console.warn(`uploadProfilePhoto( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }


  getProfileLink(uuid, name, type: ACCOUNT_TYPE) {
    let redirectUri = ''
    if (type === ACCOUNT_TYPE.STARTUP) {
      redirectUri = `/startups/profile/` + `${uuid}/${createSlug(name)}`
    } else if (type === ACCOUNT_TYPE.CORPORATE) {
      redirectUri = `/corporates/profile/` + `${uuid}/${createSlug(name)}`
    } else if (type === ACCOUNT_TYPE.MENTOR) {
      redirectUri = `/mentors/profile/` + `${uuid}/${createSlug(name) || '-'}`
    } else if (type === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      redirectUri = `/program-office-members/profile/` + `${uuid}/${createSlug(name) || '-'}`
    } else if (type === ACCOUNT_TYPE.INVESTOR) {
      redirectUri = `/investors/profile/` + `${uuid}/${createSlug(name) || '-'}`
    } else if (type === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      redirectUri = `/service-provider/profile/` + `${uuid}/${createSlug(name) || '-'}`
    }
    return redirectUri
  }

  public getCertificates(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CERTIFICATES);
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching certificates!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public verifyCertificates(certificateNo: number|string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CERTIFICATES) + '/verify/'+certificateNo;
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching certificates!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  partnerAdminLoginRequest(){
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS) + 'admin-console';
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while logging in admin console!'), 'error');
        return throwError(() => fault);
      })
    );
  }
}

<div class="row g-6 g-xl-9 mb-8" *ngIf="investors?.length">
  <!-- <h2>
    Recommended Investors
  </h2> -->
  <div class="d-flex flex-wrap">
    <span class="circle"></span>
    <h1 class="fs-1hx text-dark mb-5  ms-6">Recommended Investors</h1>
  </div>

  <swiper #swiper [spaceBetween]="30" [navigation]="false" [autoplay]="true" [virtual]="true" [pagination]="{
      clickable: true
    }" class="recommended-investor-slider" (mouseenter)="onMouseenter()" (mouseleave)="onMouseleave()" [breakpoints]="{
        '320': {
            slidesPerView: 2,
            spaceBetween: 20
        },
        '640': {
          slidesPerView: 2,
          spaceBetween: 20
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 30
        },
        '1024': {
          slidesPerView: 4,
          spaceBetween: 30
        }
      }">


    <ng-template swiperSlide *ngFor="let investor of investors">
      <app-investors-search-card [avtarSize]="'small'" [openedFrom]="'recommended'" [isShowCompareButton]="false" [investor]="investor"
      (hideRecommendedInvestor)="hideInvestor($event)"
      ></app-investors-search-card>
    </ng-template>
  </swiper>




</div>

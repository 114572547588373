import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import * as CorporateDashBoardActions from "src/app/core/state/corporate-dashboard/corporate.dashboard.action";
import * as MentorDashBoardActions from "src/app/core/state/mentors-dashboard/mentors.dashboard.action";
import * as ServiceProviderDashBoardActions from "src/app/core/state/service-provider-dashboard/service-provider.dashboard.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { ACCOUNT_TYPE, InvestorTypes } from 'src/app/core/domain';
import { getCorporateProfileCompleteness } from 'src/app/core/state/corporate-dashboard/corporate.dashboard.selectors';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness } from 'src/app/core/state/investor-dashboard';
import { getMentorProfileCompleteness } from 'src/app/core/state/mentors-dashboard/mentors.dashboard.selectors';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import * as FeedAction from 'src/app/core/state/feed';
import * as ProgramOfficeDashBoardActions from "src/app/core/state/program-office-dashboard/program-office.dashboard.action";
import { getProgramOfficeProfileCompleteness } from 'src/app/core/state/program-office-dashboard/program-office.dashboard.selectors';


@Component({
  selector: 'app-feed-comment-form',
  templateUrl: './feed-comment-form.component.html',
  styleUrls: ['./feed-comment-form.component.scss']
})
export class FeedCommentFormComponent implements OnInit {

  @Input() postId: string;
  @Input() parentCommentId: string;
  @Output() onAdded = new EventEmitter()
  profileCompleteness;
  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;

  comment = ''

  constructor(
    private feedService: CommunityFeedService,
    private store: Store,
  ) { }

  ngOnInit(): void {
    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        this.getProfileCompleteness()
      }
    })
  }

  handleSubmit() {
    const comment = this.comment;
    if (comment.trim().length) {
      this.comment = '';
      let sub;
      if (this.parentCommentId) {
        sub = this.feedService.postCommentReply(this.postId, this.parentCommentId, comment)
      } else {
        sub = this.feedService.postComment(this.postId, comment)
      }
      sub.subscribe((res) => {
        if (res) {
          this.onAdded.emit()
          // this.store.dispatch(new FeedAction.GetStats());
        }
      });
    }
  }


  getProfileCompleteness() {

    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
        this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      } else {
        this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
        this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      }

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(StartupDashBoardActions.getStartupProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
          // console.log({ res })
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getCorporateProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.dispatch(new MentorDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getMentorProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.dispatch(new ServiceProviderDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.dispatch(new ProgramOfficeDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getProgramOfficeProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

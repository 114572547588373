<div class="header shadow-sm" data-kt-sticky="true" data-kt-sticky-name="header"
  data-kt-sticky-offset="{default: '200px', lg: '300px'}">

  <div class="header-top d-flex align-items-stretch flex-grow-1">

    <div class="d-flex container-xxl align-items-stretch">

      <div class="d-flex align-items-center align-items-lg-stretch me-5 flex-row-fluid">

        <button
          class="d-lg-none btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 w-35px h-35px h-md-40px w-md-40px ms-n2 me-2"
          (click)="open()">

          <span class="svg-icon svg-icon-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                fill="currentColor" />
              <path opacity="0.3"
                d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                fill="currentColor" />
            </svg>
          </span>

        </button>



        <a [href]="brandDetails?.features?.main_website_url" target="_blank" class="d-flex align-items-center"
          *ngIf="brandDetails?.features?.main_website_url">
          <app-logo-renderer class="h-25px h-lg-40px" [headerLogo]="true"></app-logo-renderer>
        </a>
        <a routerLink="/" class="d-flex align-items-center" *ngIf="!brandDetails?.features?.main_website_url">
          <app-logo-renderer class="h-25px h-lg-40px" [headerLogo]="true"></app-logo-renderer>
        </a>


        <div class="align-self-end overflow-auto">

          <div class="header-tabs overflow-auto mx-4 ms-lg-10 mb-5 mb-lg-0">

            <ul class="nav flex-nowrap text-nowrap">
              <ng-container *ngFor="let item of navItems">
                <li class="nav-item " *ngIf="(!item.children || !item.children.length) && item.route"
                  (click)="onSelectNavItem(item)">
                  <ng-container *ngIf="(!item.featureKey || brandDetails.features[item.featureKey])">
                    <div class="nav-link header-nav-hover cursor-pointer" [class.active]="isItemSelected(item)">
                      {{ item.title }}
                    </div>
                  </ng-container>
                </li>



                <li class="nav-item" *ngIf="item.children.length && !item.route" ngbDropdown container="body"
                  id="kt_header_user_menu_toggle">
                  <div class="nav-link  header-nav-hover cursor-pointer" ngbDropdownToggle
                    [class.active]="isItemSelected(item)">
                    {{item.title}}
                  </div>


                  <div ngbDropdownMenu
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
                    data-kt-menu="true">
                    <ng-container *ngFor="let item of item.children">
                      <ng-container *ngIf="(!item.featureKey || brandDetails.features[item.featureKey])">

                        <div class="menu-item px-5"  (click)="onSelectNavItem(item)">
                          <div class="menu-link px-5"  [class.active-link]="isChildItemSelected(item)" ngbDropdownItem>{{item.title}}</div>
                        </div>

                      </ng-container>
                    </ng-container>
                  </div>

                </li>
              </ng-container>


            </ul>

          </div>

        </div>

      </div>


      <div class="d-flex align-items-center flex-row-auto">

        <div class="d-flex align-items-center ms-1" *ngIf="profileData">


          <div
            class="btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 w-35px h-35px h-md-40px w-md-40px position-relative me-5"
            id="kt_drawer_chat_toggle" routerLink="/chat/conversations" ngbTooltip="Messages" *ngIf="brandDetails?.features?.chat && profileData.accountType !== ACCOUNT_TYPE.PARTNER && profileData.accountType !== ACCOUNT_TYPE.OTHER
            && profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER">
            <i class="bi bi-chat-fill fs-1 header-icons-font-size"></i>
            <span *ngIf="notificationsCount?.unreadMessageCount"
              class="position-absolute top-10 start-85 translate-middle badge badge-circle badge-dark">
              {{ notificationsCount?.unreadMessageCount }}
            </span>
          </div>

          <div
            class="btn btn-icon btn-color-white bg-hover-white bg-hover-opacity-10 w-35px h-35px h-md-40px w-md-40px position-relative me-5"
            id="kt_drawer_chat_toggle"
            [routerLink]="notificationsCount?.pendingConnectionCount > 1? '/connections/pending-requests' : '/connections'"
            ngbTooltip="Connections" *ngIf="brandDetails.features?.connections && profileData.accountType !== ACCOUNT_TYPE.PARTNER && profileData.accountType !== ACCOUNT_TYPE.OTHER
            && profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER">
            <i class="bi bi-people-fill fs-1 header-icons-font-size"></i>
            <span *ngIf="notificationsCount?.pendingConnectionCount"
              class="position-absolute top-10 start-85 translate-middle badge badge-circle bg-green">
              {{ notificationsCount?.pendingConnectionCount }}
            </span>
          </div>



          <app-user-profile-menu *ngIf="profileData"></app-user-profile-menu>




        </div>
        <ng-container *ngIf="!profileData">
          <a routerLink="/auth/login" class="menu-link py-3 px-4 px-xxl-6 fs-5 text-white">Login</a>
          <a routerLink="/auth/register" class="btn btn-white text-black">Sign Up</a>
        </ng-container>
      </div>

    </div>
  </div>
  <app-offcanvas-sidebar [menuList]="navMenuList"></app-offcanvas-sidebar>

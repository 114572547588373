import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  of,
  throwError
} from "rxjs";
import {
  catchError, map, tap,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IElevatorPitch, ISupportingDocuments } from "../domain/pitch-deck.model";
import { IProfileDetails } from "../domain/profile.model";
import { ICorporatesSearchResponse, IInvestorSearchResponse, ILiveDealSearchResponse, IMentorSearchResponse, IMetaPagination, IServiceProviderSearchResponse, IStartUpSearchResponse } from "../domain/search.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import { ACCOUNT_TYPE } from "../domain";
import { IBrandDetails } from "../domain/brand.model";
import { Store, select } from "@ngrx/store";
import { getBrandDetails } from "../state/global";

@Injectable({
  providedIn: "root"
})
export class SearchService {
  brandDetails: Partial<IBrandDetails>;

  constructor(
    private http: HttpClient,
    private toastService: ToastAlertService,
    private globalService: GlobalService,
    private store: Store
  ) {
    this.store.pipe(select(getBrandDetails)).subscribe((res) => {
      if (res) {
        this.brandDetails = res
      }
    })

  }

  public searchStartUp(filters = {}): Observable<{ items: IStartUpSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchStartUp GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching startups!'), 'error');
        console.warn(`searchStartUp Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public searchInvestor(filters = {}): Observable<{ items: IInvestorSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchInvestor GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching investors!'), 'error');
        console.warn(`searchInvestor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchCorporates(filters = {}): Observable<{ items: ICorporatesSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CORPORATE_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchCorporates GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching corporates!'), 'error');
        console.warn(`searchCorporates Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchMentor(filters = {}): Observable<{ items: IMentorSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTOR_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchMentor GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching mentor!'), 'error');
        console.warn(`searchMentor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchProgramOffice(filters = {}): Observable<{ items: IMentorSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PROGRAM_OFFICE_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchMentor GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching mentor!'), 'error');
        console.warn(`searchMentor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }
  public searchServiceProviders(filters = {}): Observable<{ items: IServiceProviderSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SERVICE_PROVIDERS_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchMentor GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching mentor!'), 'error');
        console.warn(`searchMentor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchPartners(filters = {}): Observable<{ items: IServiceProviderSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        console.info(`searchPartners GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching partner!'), 'error');
        console.warn(`searchPartners Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchIndividuals(filters = {}): Observable<{ items: IServiceProviderSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_SEARCH);
    return this.http.get(url, {
      params: filters
    }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching partner!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public searchLiveDeals(filters = {}): Observable<{ items: ILiveDealSearchResponse[], meta: IMetaPagination }> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.LIVE_DEAL_SEARCH);
    return this.http.get(url, {
      params: { ...filters, liveDeal: true }
    }).pipe(
      map((response: any) => {
        console.info(`searchLiveDeals GET`);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching live deals!'), 'error');
        console.warn(`searchLiveDeals Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }



  public searchStartUpByName(name: string = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-startups/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchStartUp GET`);
        return response?.data?.startups || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching startups!'), 'error');
        console.warn(`searchStartUp Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public searchInvestorByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-investors/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchInvestor GET`);
        return response?.data?.investors || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching investors!'), 'error');
        console.warn(`searchInvestor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchCorporateByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CORPORATE_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-corporates/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchCorporate GET`);
        return response?.data?.corporates || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching corporate!'), 'error');
        console.warn(`searchCorporate Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public searchMentorByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MENTOR_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-mentors/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchMentor GET`);
        return response?.data?.mentors || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching mentor!'), 'error');
        console.warn(`searchMentor Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchServiceProviderByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SERVICE_PROVIDERS_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-service-providers/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchServiceProviderByName GET`);
        return response?.data?.serviceProviders || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching service providers!'), 'error');
        console.warn(`searchServiceProviderByName Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchProgramOfficeByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PROGRAM_OFFICE_SEARCH) + '/profile/' + name;
    // if (this.brandDetails?.features?.search_type === 'elastic') {
    //   url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-service-providers/` + name;
    // }
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`searchServiceProviderByName GET`);
        return response?.data?.serviceProviders || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching service providers!'), 'error');
        console.warn(`searchServiceProviderByName Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }



  public searchPartnerByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PARTNERS_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-partners/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data?.partners || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching partners!'), 'error');
        console.warn(`searchPartnerByName Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public searchIndividualsByName(name = ''): Observable<{ uuid: string; name: string; logo: string }[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_SEARCH) + '/profile/' + name;
    if (this.brandDetails?.features?.search_type === 'elastic') {
      url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `search-individuals/` + name;
    }
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data?.partners || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching individuals!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  saveSearchFilter(filter) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_SEARCH_FILTER);
    return this.http.post(url, filter).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving filter!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  updateSearchFilter(filter, filterId: string) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_SEARCH_FILTER) + '/' + filterId;
    return this.http.patch(url, filter).pipe(
      tap(() => {
        this.toastService.showToast('Filter updated');
      }),
      // map((response: any) => {
      //   return response.data;
      // }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating filter!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  getAllSearchFilter() {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_SEARCH_FILTER);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving filter!'), 'error');
        return throwError(() => fault);
      })
    );
  }
  getSearchFilter(type: ACCOUNT_TYPE) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_SEARCH_FILTER) + `/${type}`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving filter!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  deleteSearchFilter(filterId) {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER_SEARCH_FILTER) + `/${filterId}`;
    return this.http.delete(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting filter!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public globalElasticSearch(name = '', endPoint = 'search-global'): Observable<{ uuid: string; name: string; logo: string; accountType: ACCOUNT_TYPE; searchType: string,  viewAll: boolean,redirectUri: string}[]> {
    if (name.length < 3) {
      return of([]);
    }
    let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ELASTIC_SEARCH) + `${endPoint}/` + name;
    return this.http.get(url).pipe(
      map((response: any) => {
        if(typeof response?.data === 'object') {
          const isKey = Object.keys(response?.data)[0];
          if(isKey) {
            return response?.data[isKey]
          }
        }
        return response?.data?.response || response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching search!'), 'error');
        return throwError(() => fault);
      })
    );
  }

}

import { IStartUpSearchResponse } from "./search.model";

export interface PartnersInformation {
    uuid: string;
    name: string;
    partnerType: string;
    website: string;
    shortDescription: string;
    longDescription: string;
    logo: string;
    tagline: string;
    tags: string;
    location: string;
    inviteCode: string;
    startups?: IStartUpSearchResponse[]
    applicationLink: string;
}



export interface PartnerProfileCompleteness {
  total: number;
  completed: number;
  percentage: number;
  canRequestApproval: boolean;
  canToggleStatus: boolean;
  isApprovalRequested: boolean;
  isProvidingFundings: boolean;
  forms: any;
  isRejected: boolean;
  isApproved: boolean
  approvalStatus: string
}

export enum PartnerType {
  INCUBATOR = 'incubator',
  ACCELERATOR = 'accelerator',
  ASSOCIATION_ORGANIZATION = 'association-organization',
}

export const PartnerTypeLabelMap =  {
  [PartnerType.INCUBATOR]:'Incubator',
  [PartnerType.ACCELERATOR]: 'Accelerator',
  [PartnerType.ASSOCIATION_ORGANIZATION] : 'Association organization',
}


<div class="card shadow-sm h-100">

  <div class="card position-relative">

    <div class="card-body px-5 py-5 d-flex">
      <div>
        <img [src]="item.favicon" style="width: 50px;height: 50px;" />
      </div>
      <div class="ms-3">
        <h5 class="card-title fs-2">{{item.title}}</h5>
        <div class="d-flex">
          <!-- <p class="mb-1 me-3"><span class="text-primary">{{item.createdAt | date:'mediumDate'}}</span> </p> -->

          <p class="mb-1 text-gray-600">
            <b>Courtesy:</b> {{item.host}}
          </p>
        </div>
        <!-- <p *ngIf="item.industries" class="card-text text-gray-600  mb-2 text-truncate" [ngbTooltip]="item.industries"><span class="text-black">Industries:</span> {{item.industries || '-'}}</p> -->
        <p class="fs-5 card-text text-gray-600 mb-0 truncate-2-line" [innerHTML]="item.description"
          [ngbTooltip]="item.description">
        </p>
      </div>

      <div>
        <a class="btn btn-primary text-white ls-2 btn-sm  text-end  w-fit no-wrap" [href]="item.newsUrl"
          target="_blank">
          Visit link
        </a>
      </div>
    </div>

  </div>


</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails, getGlobalSettings } from 'src/app/core/state/global';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, finalize, first, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { ProfileService } from 'src/app/core/service/profile.service';
@Component({
  selector: 'app-account-setting-page-top-nav',
  templateUrl: './account-setting-page-top-nav.component.html',
  styleUrls: ['./account-setting-page-top-nav.component.scss']
})
export class AccountSettingPageTopNavComponent implements OnInit, OnDestroy {

  brandDetails: Partial<IBrandDetails>;
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  private destroyed$: Subject<void> = new Subject();

  navLinks: any = [];
  rightSideTabs: any = [
    {
      route: '/platform-updates',
      title: 'Platform updates',
      showMenu: true,
      featureKey: 'product_updates'
    }
  ];

  constructor(
    private readonly store: Store,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res;



        this.getProfileData$ = this.store.pipe(select(getProfileData));

        this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
          if (res) {
            this.profileData = res;

            this.navLinks = [
              {
                route: '/account/edit/profile',
                title: 'Personal Information',
                showMenu: true,
              },

            ];


            if(this.profileData.accountType !== ACCOUNT_TYPE.INDIVIDUAL) {
              this.navLinks.push( {
                route: '/account/edit/notification',
                title: 'Notification Settings',
                showMenu: true,
              },)
            }

            if(this.profileData.accountType !== ACCOUNT_TYPE.JOB_SEEKER && this.profileData.accountType !== ACCOUNT_TYPE.PARTNER && this.profileData.accountType !== ACCOUNT_TYPE.INDIVIDUAL) {
              this.navLinks.push({
                route: '/account/edit/availability',
                title: 'Availability Hours',
                showMenu: this.brandDetails?.features?.online_meetings,
              },)
            }

            if(this.profileData.canAddMembers) {
              this.navLinks.push({
                route: '/account/edit/team',
                title: 'Team Account Access',
                showMenu: true,
              },)
            }

            // if(this.brandDetails?.features?.certificates && this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
            //   this.profileService.getCertificates().subscribe((res) => {
            //     console.log('res', res)
            //     if (res) {
            //       if(!this.navLinks.find((e)=>e.title === 'Certificate')) {
            //         if((res.data || []).length) {
            //           this.navLinks.push({
            //             route: '/account/edit/certificates',
            //             title: 'Certificate',
            //             showMenu: true,
            //           },)
            //         }
            //       }
            //     }
            //   })
            // }

          }
        })
      }
    })
  }


  ngOnDestroy(): void {
    this.destroyed$.complete()
  }

}

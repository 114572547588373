<div class="card cursor-pointer overlay border border-2 card-bordered shadow" (click)="openDetailsView()"
  [ngbPopover]="popContent" container="body" [placement]="['left', 'right','top']" triggers="mouseenter:mouseleave"
  popoverClass="popover-custom-width">
  <div class="card-body overlay-wrapper text-left p-0 cardImageSection ribbon ribbon-start ribbon-clip">
    <img class="w-100 rounded" [src]="s3logoUrl" />

    <div class="ribbon-label co-investing-badge"
      *ngIf="startup.isLiveDeal && profileData?.accountType !== ACCOUNT_TYPE.STARTUP">
      CO-INVESTING
      <span class="ribbon-inner bg-primary"></span>
    </div>

    <div class="badge rounded compare-badge" [ngClass]="isStartupInCompareList ? 'badge-secondary' : 'badge-primary'"
      [class.disabled]="
            !isStartupInCompareList && isMaxComparableItemsSelected
          "
      *ngIf="brandDetails?.features?.profile_comparison && !appliedFilters?.liveDeal && profileData && isShowCompareButton"
      (click)="
            isStartupInCompareList
              ? removeFromCompare($event)
              : addToCompare($event)">
      {{ isStartupInCompareList ? "Remove from Compare" : "Add to Compare" }}
    </div>

    <div class="badge rounded partner-badge shadow-sm" *ngIf="startup?.partner">
      {{startup.partner.name}}
    </div>

    <div class="badge rounded compare-badge badge-dark" *ngIf="openedFrom==='recommended'" (click)="
    hideStartup($event)">
      Hide
    </div>




    <div
      class="rounded-100 text-white position-absolute top-10px end-10px d-flex justify-content-center align-items-center w-40px h-40px {{profileComplenessClass}}"
      *ngIf="showCompleteness">
      {{startup.profileCompletenessPercent}}%
    </div>


    <div class="mb-0 nameSection">
      <h3 class="fw-bolder text-white fs-1x mb-3 text-truncate" ngbTooltip="{{ startup.companyName }}"
        [class.mb-2]="!startup.startupIndustries?.length">
        {{ startup.companyName }}
      </h3>
      <div class="text-white" *ngIf="brandDetails?.features?.startup_show_programs && programs?.length">
        <span class="badge badge-search-cards text-white opacity-10 fw-bold mb-2 fs-7 me-3"
          *ngFor="let program of programs">
          {{ program }}
        </span>

      </div>
      <div class="row elevator-pitch-layer w-100"
        *ngIf="startup.startupIndustries?.length && brandDetails?.features?.industries_technologies_section">
        <div class="col-2  text-white w-100 fs-6 mb-2 fw-normal mt-2">
          <span class="badge badge-search-cards text-white opacity-10 fw-bold mb-2 fs-7 me-3"
            *ngFor="let industry of startup.startupIndustries.slice(0,3)">
            {{ industry }}
          </span>

          <span class="badge badge-search-cards text-white opacity-10 fw-bold mb-2 fs-7 me-3"
            [ngbTooltip]="startup.startupIndustries.join(', ')"
            *ngIf="startup.startupIndustries.length > 3">+{{startup.startupIndustries.length - 3}}</span>
        </div>
      </div>


      <!-- <div class="row w-100 elevator-pitch-layer">
        <div class="max-h-100px text-white w-100 fs-7 fw-normal my-2 overflow-hidden"
          [class.mt-0]="!startup.startupIndustries?.length">
          {{startup.elevatorPitch}}
        </div>

      </div> -->

    </div>
  </div>

  <div class="card-footer d-flex align-items-center p-3">

    <span class="badge  badge-secondary text-dark  badge-custom-search-page fs-8 bg-white text-truncate"
      style="max-width: 50%;" [ngbTooltip]="startup?.productInformation?.productStage?.name">
      <span class="text-truncate">
        {{startup?.productInformation?.productStage?.name || 'N/A'}}
      </span>
    </span>

    <span class="badge  badge-secondary text-dark ms-2 badge-custom-search-page fs-8 bg-white text-truncate"
      style="max-width: 50%;" [ngbTooltip]="startup?.financials?.fundingStage?.name"
      *ngIf="profileData?.accountType === ACCOUNT_TYPE.INVESTOR">

      <span class="text-truncate">
        {{startup?.financials?.fundingStage?.name || 'N/A'}}
      </span>
    </span>

    <span [ngbTooltip]="startup?.startupTechnologies?.join(', ')" *ngIf="profileData?.accountType !== ACCOUNT_TYPE.INVESTOR
      && brandDetails?.features?.industries_technologies_section"
      class="badge  badge-secondary text-dark ms-2 badge-custom-search-page fs-8 bg-white" style="max-width: 50%;">

      <ng-container *ngIf="startup?.startupTechnologies?.length">
        <span class="text-truncate"> {{startup?.startupTechnologies[0]}}</span>

        <span *ngIf="startup?.startupTechnologies?.length > 1">&nbsp;(+{{startup.startupTechnologies.length -
          1}})</span>
      </ng-container>

      <ng-container *ngIf="!startup?.startupTechnologies?.length">
        N/A
      </ng-container>
    </span>


    <!-- data.items[2].productInformation.productStage.name -->
    <!-- data.items[2].financials.fundingStage.name -->

    <!-- <div class="col-md-2 col-xxl-2">
      <div class="symbol symbol-circle me-3"
        [ngClass]="{'symbol-40px' : avtarSize === 'large' , 'symbol-25px' : avtarSize === 'small'}">
        <ngx-avatars name="{{ startup.user?.name | slice: 0:1 }}" [size]="avtarSize === 'small'? 20: 40"
          *ngIf="!avtarUrl">
        </ngx-avatars>
        <div class="symbol " [ngClass]="{'symbol-40px' : avtarSize === 'large' , 'symbol-25px' : avtarSize === 'small'}"
          *ngIf="avtarUrl">
          <img alt="Logo" src="{{ avtarUrl }}" style="border-radius: 100%" />
        </div>
      </div>
    </div>
    <div class="col-md-10 col-xl-12 col-xxl-10 text-truncate">
      <span class="fw-bolder text-gray-800 cursor-pointer text-hover-primary fs-4 d-block text-truncate ps-2"
        ngbTooltip="{{ startup.user?.name }}">
        {{ startup.user?.name | titlecase }}
      </span>
    </div> -->
  </div>
</div>
<ng-template #popContent>
  <!-- <div *ngIf="brandDetails?.features?.startup_show_programs && programs">
    <p>
      Programs: {{programs}}
    </p>

  </div> -->
  <div>
    {{startup.elevatorPitch}}
  </div>
</ng-template>

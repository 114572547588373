import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails } from 'src/app/core/domain/profile.model';
import { getProfileData } from 'src/app/core/state/profile';
import { getGlobalSettings } from 'src/app/core/state/global';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { CommunityFeedService } from 'src/app/core/service/community-feed.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FeedFormImageSelectModalComponent } from './feed-form-image-select-modal/feed-form-image-select-modal.component';
import { CommunityWallFileType } from 'src/app/core/domain/community-feed';
import { FeedFormFileSelectModalComponent } from './feed-form-file-select-modal/feed-form-file-select-modal.component';
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard";
import * as CorporateDashBoardActions from "src/app/core/state/corporate-dashboard/corporate.dashboard.action";
import * as MentorDashBoardActions from "src/app/core/state/mentors-dashboard/mentors.dashboard.action";
import * as ServiceProviderDashBoardActions from "src/app/core/state/service-provider-dashboard/service-provider.dashboard.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { ACCOUNT_TYPE, InvestorTypes } from 'src/app/core/domain';
import { getCorporateProfileCompleteness } from 'src/app/core/state/corporate-dashboard/corporate.dashboard.selectors';
import { getIndividualInvestorProfileCompleteness, getInvestorProfileCompleteness } from 'src/app/core/state/investor-dashboard';
import { getMentorProfileCompleteness } from 'src/app/core/state/mentors-dashboard/mentors.dashboard.selectors';
import { getServiceProviderProfileCompleteness } from 'src/app/core/state/service-provider-dashboard/service-provider.dashboard.selectors';
import * as ProgramOfficeDashBoardActions from "src/app/core/state/program-office-dashboard/program-office.dashboard.action";

import * as FeedAction from 'src/app/core/state/feed';
import { getProgramOfficeProfileCompleteness } from 'src/app/core/state/program-office-dashboard/program-office.dashboard.selectors';

const GlobalQuillConfig = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['link', /*'image', 'video'*/]                         // link and image, video
  ]
}

const blankPostData = {
  text: "",
  image: "",
  url: "",
  file: "",
  images: []
}

@Component({
  selector: 'app-community-feed-form',
  templateUrl: './community-feed-form.component.html',
  styleUrls: ['./community-feed-form.component.scss']
})
export class CommunityFeedFormComponent implements OnInit, OnDestroy {
  quillModules = GlobalQuillConfig;
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  postData = { ...blankPostData }
  imageSrc: Array<any> = [];
  selectedImage: Array<any> = [];
  selectedFile;
  profileCompleteness;

  @Output() onAdded = new EventEmitter()

  constructor(
    private store: Store,
    private communityFeedService: CommunityFeedService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));

    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res
      }
    })

    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
        this.getProfileCompleteness()
      }
    })
  }

  async handleSubmit() {

    if (this.selectedImage.length) {

      this.postData.images = []

      for (let index = 0; index < this.selectedImage.length; index++) {
        const file = this.selectedImage[index];

        const res = await this.communityFeedService.uploadFile(file, CommunityWallFileType.IMAGE).toPromise()
        // .subscribe((res) => {
        console.log('imageeee', res)
        const url = res.data;
        this.postData.images.push(url);
        // this.postData.image = url;
        // })
      }


      this.communityFeedService.createPost(this.postData).subscribe((res) => {
        if (res) {
          this.onAdded.emit()
          this.postData = { ...blankPostData }
          // this.clearImageSelection(index);
          this.selectedImage = [];
          this.imageSrc = [];
          // this.store.dispatch(new FeedAction.GetStats());
        }
      })

    } else if (this.selectedFile) {
      this.communityFeedService.uploadFile(this.selectedFile, CommunityWallFileType.FILE).subscribe((res) => {
        console.log('imageeee', res)
        const url = res.data;
        this.postData.file = url;
        this.communityFeedService.createPost(this.postData).subscribe((res) => {
          if (res) {
            this.onAdded.emit()
            this.postData = { ...blankPostData }
            this.clearFileSelection();
            // this.store.dispatch(new FeedAction.GetStats());
          }
        })

      })
    } else {
      this.communityFeedService.createPost(this.postData).subscribe((res) => {
        if (res) {
          this.onAdded.emit()
          this.postData = { ...blankPostData }
          // this.store.dispatch(new FeedAction.GetStats());
        }
      })

    }

  }

  handleSelectImage() {
    const modalRef = this.modalService.open(FeedFormImageSelectModalComponent)
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.result.then((res) => {
      if (res?.data) {
        console.log('resssss', res.data)
        // this.handleSubmit(res.data);
        this.selectedImage = res.data;
        this.selectedImage.forEach((f) => {
          const reader = new FileReader();
          reader.onload = e => this.imageSrc.push(reader.result);

          reader.readAsDataURL(f);
        })
      }
    });
  }

  handleSelectFile() {
    const modalRef = this.modalService.open(FeedFormFileSelectModalComponent)
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.result.then((res) => {
      if (res?.data) {
        console.log('resssss', res.data)
        this.selectedFile = res.data;
      }
    });
  }

  closeModal () {
    this.modalService.dismissAll()
  }

  get fileExtension() {
    if (!this.selectedFile) {
      return ''
    }
    const splitted = this.selectedFile.name.split('.')
    return splitted[splitted.length - 1];
  }

  clearImageSelection(i) {
    // this.selectedImage = [];
    // this.imageSrc = [];
    this.selectedImage.splice(i, 1);
    this.imageSrc.splice(i, 1);
  }

  clearFileSelection() {
    this.selectedFile = undefined;
  }


  getProfileCompleteness() {

    if (this.profileData.accountType === ACCOUNT_TYPE.INVESTOR) {

      if (this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
        this.store.pipe(select(getIndividualInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      } else {
        this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
        this.store.pipe(select(getInvestorProfileCompleteness)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(res => {
          if (res) {
            this.profileCompleteness = res;
          }
        })
      }

    } else if (this.profileData.accountType === ACCOUNT_TYPE.STARTUP) {
      this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(StartupDashBoardActions.getStartupProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
          console.log({ res })
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.CORPORATE) {
      this.store.dispatch(new CorporateDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getCorporateProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    } else if (this.profileData.accountType === ACCOUNT_TYPE.MENTOR) {
      this.store.dispatch(new MentorDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getMentorProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      this.store.dispatch(new ServiceProviderDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getServiceProviderProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });
    }
    else if (this.profileData.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE) {
      this.store.dispatch(new ProgramOfficeDashBoardActions.GetProfileCompleteness());
      this.store.pipe(select(getProgramOfficeProfileCompleteness)).pipe(takeUntil(this.destroyed$)).subscribe((res: any) => {
        if (res) {
          this.profileCompleteness = res;
        }
      });

    }
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: "root"
})
export class ApiEndpointService {
    /**
     * Map of domains for API endpoints.
     */
    public static DOMAIN = environment.apiEndpoints.baseUrl;

    /**
     * Map of contexts for API endpoints.
     */
    public static CONTEXT = "api/v1/";
    public static CONTEXT_v2 = "api/v2/";

    /**
     * Map of contexts for API endpoints.
     */
    public static AUTH_CONTEXT = "auth/";
    public static GLOBAL = 'public/global/'
    public static INVESTOR = 'investors/'
    public static USERS = "users/";
    public static STARTUP_CONTEXT = "startups/";
    public static FAQ_CONTEXT = "faqs";
    public static USER = 'users/';
    public static COMPARE = 'compare/'
    public static MEETINGS = 'meetings/'
    public static CONNECTIONS = 'connections'
    public static PUBLIC_STARTUP_CONTEXT = "startups/public/";
    public static NOTIFICATIONS = 'notifications'
    public static CORPORATES = 'corporates/'
    public static MENTORS = 'mentors/'
    public static INDIVIDUALS = 'individuals/'
    public static PROGRAM_OFFICE = 'program_office_members/'
    public static SERVICE_PROVIDERS = 'service_providers/'
    public static PARTNERS = 'partners/'
    public static JOBS = 'jobs/'
    public static IP_MANAGEMENT = 'ip-management/'
    public static MATRICS = 'metrics/'
    public static MENTORSHIP = 'mentorship/'
    public static CHALLENGES = 'challenges/'
    public static CHALLENGE_PARTICIPANTS = 'participants/'
    public static ADMIN_ACTIONS = 'admin-actions/'
    public static FORMS_MANAGEMENT = 'forms-management/'
    public static POWER_PITCH = 'power-pitch/'
    public static PROGRAMS_MANAGEMENT = 'programs-management/'
    public static VS_PROGRAMS_MANAGEMENT = 'vs-programs-management/'
    public static EVENTS = 'events/'
    public static COMMUNITY_FEED = 'community-wall/'
    public static CONVERSATIONS = 'conversations/'
    public static CHAT = 'chat/conversation/'
    public static ELASTIC_SEARCH = 'elastic-search/'




    /**
     * Map of contexts for API endpoints.
     */
    public static PUBLIC_CONTEXT = "public/";

    /**
     * Map of API endpoints.
     */
    public static ENDPOINT = {
        MOBILE_LOGIN: `public/auth/mobile/login`,
        MOBILE_LOGIN_VERIFY_OTP: `public/auth/mobile/login/verify`,
        VERIFY_MOBILE: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.AUTH_CONTEXT}verify/mobile/`,
        VERIFY_EMAIL: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.AUTH_CONTEXT}verify/email/`,
        REGISTER: `public/auth/register/`,
        GET_FAQ: `public/faqs`,
        GET_GLOSSARY: `public/glossary`,
        STARTUP_INFO: `${ApiEndpointService.STARTUP_CONTEXT}startup-information`,
        STARTUP_LOGO_UPLOAD: `${ApiEndpointService.STARTUP_CONTEXT}upload/logo`,
        FOUNDERS: `${ApiEndpointService.STARTUP_CONTEXT}founders`,
        ADVISORY_BOARDS: `${ApiEndpointService.STARTUP_CONTEXT}advisory-boards`,
        INDUSTRY_TECHNOLOGY: `${ApiEndpointService.STARTUP_CONTEXT}industry-technology-business`,
        PRODUCT_INFO: `${ApiEndpointService.STARTUP_CONTEXT}product-information`,
        FINANCIALS: `${ApiEndpointService.STARTUP_CONTEXT}financials-information`,
        ONGOING_COMMITMENTS: `${ApiEndpointService.STARTUP_CONTEXT}ongoing-commitments`,
        PITCH_DECK: `${ApiEndpointService.STARTUP_CONTEXT}pitch-deck`,
        GLOBAL_SETTINGS: `${ApiEndpointService.GLOBAL}settings`,
        GLOBAL: `${ApiEndpointService.GLOBAL}`,
        PUBLIC_CUSTOM: `${ApiEndpointService.GLOBAL}custom/`,
        PRODUCT_STAGES: `${ApiEndpointService.GLOBAL}product_stages`,
        PITCH_VIDEO_TOPICS: `${ApiEndpointService.GLOBAL}pitch_video_topics`,
        FUNDING_STAGES: `${ApiEndpointService.GLOBAL}funding_stages`,
        BUSINESS_MODELS: `${ApiEndpointService.GLOBAL}business_models`,
        DELIVERY_MODELS: `${ApiEndpointService.GLOBAL}delivery_models`,
        MATURITY_STAGES: `${ApiEndpointService.GLOBAL}maturity_stages`,
        ORGANIZATION_TYPE: `${ApiEndpointService.GLOBAL}custom/organization_types`,
        ORGANIZATION_INVESTMENT_TYPE: `${ApiEndpointService.GLOBAL}custom/investment_mechanisms,investment_stages,industries,investment_preferences,investability_metrics,business_models`,
        INVESTOR_ORGANIZATION_INFO: `${ApiEndpointService.INVESTOR}organization-information`,
        INVESTOR_REPRESENTATIVE_INFO: `${ApiEndpointService.INVESTOR}representative-information`,
        INVESTOR_INVESTMENT_INFO: `${ApiEndpointService.INVESTOR}investments-information`,
        INVESTOR_LOGO_UPLOAD: `${ApiEndpointService.INVESTOR}upload/logo`,
        USER_PROFILE: `${ApiEndpointService.USER}profile`,
        CERTIFICATES: `certificates`,
        USER_PROFILE_TYPES: `${ApiEndpointService.USER}profile-types`,
        USER_PUBLIC_PROFILE: `${ApiEndpointService.USER}public_profile`,
        USER_AVTAR: `${ApiEndpointService.USER}upload/avatar`,
        USER: `${ApiEndpointService.USER}`,
        SIGN_UP_SEND_OTP: `${ApiEndpointService.PUBLIC_CONTEXT}otp_verifications/send`,
        SIGN_UP_VERIFY_OTP: `${ApiEndpointService.PUBLIC_CONTEXT}otp_verifications/verify`,
        EMAIL_RESOURCES: `resources`,
        GET_RESOURCES: `${ApiEndpointService.PUBLIC_CONTEXT}resources`,
        STARTUP_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/industries,technologies,business_models,product_stages,funding_stages,investment_mechanisms,investment_stages,domain_areas,industries_primary,programs_management,industry_sub_categories`,
        ELASTIC_SEARCH: `${ApiEndpointService.ELASTIC_SEARCH}`,
        STARTUP_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/startups`,
        STARTUP_DASHBOARD: `${ApiEndpointService.STARTUP_CONTEXT}dashboard`,
        STARTUP_PROFILE_COMPLETENESS: `${ApiEndpointService.STARTUP_CONTEXT}profile_completeness`,
        INVESTOR_DASHBOARD: `${ApiEndpointService.INVESTOR}dashboard`,
        STARTUP_DASHBOARD_APPROVAL: `${ApiEndpointService.STARTUP_CONTEXT}request/approval`,
        STARTUP_DASHBOARD_RAISING_FUNDS: `${ApiEndpointService.STARTUP_CONTEXT}toggle/raising-funds`,
        TEAM_MEMBERS: `${ApiEndpointService.USERS}team-members`,
        LOAD_PITCH_DECK: `pitch-deck-recorder`,
        LOAD_PITCH_DECK_STARTUP: `pitch-deck-recorder/startup`,

        UPLOAD_PITCH_DECK: `pitch-deck-recorder/upload`,
        INVESTOR_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/investors`,
        INVESTOR_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/investment_mechanisms,industries,investment_preferences,investment_stages`,
        INVESTOR_PROFILE_COMPLETENESS: `${ApiEndpointService.INVESTOR}organization/profile_completeness`,
        INDIVIDUAL_INVESTOR_PROFILE_COMPLETENESS: `${ApiEndpointService.INVESTOR}individual/profile_completeness`,

        UPDATE_PITCH_DECK_SCRIPT:`pitch-deck-recorder/script`,

        LIVE_DEAL_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/live-deals`,
        PAGES: `${ApiEndpointService.PUBLIC_CONTEXT}pages`,
        COMPILE_PITCH_DECK: `pitch-deck-recorder/compile/request`,
        DELETE_PITCH_DECK: `pitch-deck-recorder/delete`,
        BULK_DELETE_PITCH_DECK: `pitch-deck-recorder/bulk_delete`,
        STARTUP_PUBLIC_PROFILE: `${ApiEndpointService.STARTUP_CONTEXT}public/startup-information/`,
        INVESTORS_PUBLIC_PROFILE: `${ApiEndpointService.INVESTOR}public/profile/`,
        INVESTOR_DASHBOARD_APPROVAL: `${ApiEndpointService.INVESTOR}request/approval`,
        INVESTOR_DASHBOARD_PROVIDING_FUNDS: `${ApiEndpointService.INVESTOR}toggle/providing-funding`,
        STARTUP_COMPARE: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.COMPARE}startups`,
        INVESTOR_COMPARE: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.COMPARE}investors`,
        MEETINGS: `${ApiEndpointService.MEETINGS}`,
        MEETINGS_PUBLIC: `${ApiEndpointService.MEETINGS}public/`,
        MEETING_NOTES: `${ApiEndpointService.MEETINGS}notes/`,
        STARTUP_KIT: `${ApiEndpointService.PUBLIC_CONTEXT}startup-kit`,
        STARTUP_KIT_DETAIL: `${ApiEndpointService.PUBLIC_CONTEXT}startup-kit/service/`,
        GLOBAL_BANNERS: `${ApiEndpointService.GLOBAL}banners`,
        GLOBAL_TESTIMONIALS: `${ApiEndpointService.GLOBAL}testimonials`,
        CLAIM_LISTINGS: `${ApiEndpointService.PUBLIC_CONTEXT}listings/claim/`,
        CONNECTIONS: `${ApiEndpointService.CONNECTIONS}`,
        EVALUATION: `${ApiEndpointService.STARTUP_CONTEXT}evaluation/evaluation/list`,
        STARTUP_PUBLIC_INFO:`${ApiEndpointService.PUBLIC_STARTUP_CONTEXT}startup-information`,
        STARTUP_RATING:`${ApiEndpointService.STARTUP_CONTEXT}evaluation/ratings`,
        NOTIFICATIONS: `${ApiEndpointService.NOTIFICATIONS}`,
        CONTACT: `${ApiEndpointService.GLOBAL}contact`,

        CORPORATES_INFO: `${ApiEndpointService.CORPORATES}corporate-information`,
        ENGAGEMENT_INFO: `${ApiEndpointService.CORPORATES}engagement-information`,
        CORPORATE_PROFILE_COMPLETENESS: `${ApiEndpointService.CORPORATES}profile_completeness`,
        CORPORATE_LOGO_UPLOAD: `${ApiEndpointService.CORPORATES}upload/logo`,
        CORPORATE_DASHBOARD_APPROVAL: `${ApiEndpointService.CORPORATES}request/approval`,
        CORPORATE_PUBLIC_PROFILE: `${ApiEndpointService.CORPORATES}public/corporate-information`,
        CORPORATE_DASHBOARD: `${ApiEndpointService.CORPORATES}dashboard`,
        CORPORATE_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/corporates`,


        MENTORS_INFO: `${ApiEndpointService.MENTORS}mentor-information`,
        // ENGAGEMENT_INFO: `${ApiEndpointService.MENTORS}engagement-information`,
        MENTOR_PROFILE_COMPLETENESS: `${ApiEndpointService.MENTORS}profile_completeness`,
        MENTOR_LOGO_UPLOAD: `${ApiEndpointService.MENTORS}upload/logo`,
        MENTOR_DASHBOARD_APPROVAL: `${ApiEndpointService.MENTORS}request/approval`,
        MENTOR_PUBLIC_PROFILE: `${ApiEndpointService.MENTORS}public/mentor-information`,
        MENTOR_DASHBOARD: `${ApiEndpointService.MENTORS}dashboard`,
        MENTOR_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/mentors`,
        MENTOR_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/industries,domain_areas,technologies`,



        PROGRAM_OFFICE_INFO: `${ApiEndpointService.PROGRAM_OFFICE}program-office-member-information`,
        PROGRAM_OFFICE_PROFILE_COMPLETENESS: `${ApiEndpointService.PROGRAM_OFFICE}profile_completeness`,
        PROGRAM_OFFICE_LOGO_UPLOAD: `${ApiEndpointService.PROGRAM_OFFICE}upload/logo`,
        PROGRAM_OFFICE_DASHBOARD_APPROVAL: `${ApiEndpointService.PROGRAM_OFFICE}request/approval`,
        PROGRAM_OFFICE_PUBLIC_PROFILE: `${ApiEndpointService.PROGRAM_OFFICE}public/program-office-member-information`,
        PROGRAM_OFFICE_DASHBOARD: `${ApiEndpointService.PROGRAM_OFFICE}dashboard`,
        PROGRAM_OFFICE_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/program-office-members`,
        PROGRAM_OFFICE_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/industries,domain_areas,technologies`,


        SERVICE_PROVIDER_INFO: `${ApiEndpointService.SERVICE_PROVIDERS}service-provider-information`,
        SERVICE_PROVIDERS_PROFILE_COMPLETENESS: `${ApiEndpointService.SERVICE_PROVIDERS}profile_completeness`,
        SERVICE_PROVIDERS_LOGO_UPLOAD: `${ApiEndpointService.SERVICE_PROVIDERS}upload/logo`,
        SERVICE_PROVIDERS_DASHBOARD_APPROVAL: `${ApiEndpointService.SERVICE_PROVIDERS}request/approval`,
        SERVICE_PROVIDERS_PUBLIC_PROFILE: `${ApiEndpointService.SERVICE_PROVIDERS}public/service-provider-information`,
        SERVICE_PROVIDERS_DASHBOARD: `${ApiEndpointService.SERVICE_PROVIDERS}dashboard`,
        SERVICE_PROVIDERS_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/service-providers`,
        SERVICE_PROVIDERS_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/industries,service_provider_categories,service_provider_types`,

        PARTNERS_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/partners`,



        PUBLIC_CONNECTION :  `${ApiEndpointService.PUBLIC_CONTEXT}connections/action`,
        MEDIA_IN_NEWS :  `${ApiEndpointService.PUBLIC_CONTEXT}media/in-news`,
        MEDIA_COMMUNITY_FEED :  `${ApiEndpointService.PUBLIC_CONTEXT}media/community-feed`,

        PARTNERS : `${ApiEndpointService.PARTNERS}`,
        PARTNERS_INFORMATION : `partners/${ApiEndpointService.PUBLIC_CONTEXT}partners-information`,
        PARTNER_INFORMATION : `partners/partner-information`,
        PARTNERS_LOGO_UPLOAD: `${ApiEndpointService.PARTNERS}upload/logo`,
        PARTNERS_INVITE_CSV: `${ApiEndpointService.PARTNERS}upload/csv`,
        PARTNERS_SUBMISSION : `partners/${ApiEndpointService.PUBLIC_CONTEXT}submission`,
        PARTNER_ADD_STARTUP : `partners/add-startup`,
        PARTNER_PROFILE_COMPLETENESS: `${ApiEndpointService.PARTNERS}profile_completeness`,
        PARTNER_DASHBOARD_APPROVAL: `${ApiEndpointService.PARTNERS}request/approval`,


        ADS: `${ApiEndpointService.GLOBAL}ads`,
        COUNTRIES: `${ApiEndpointService.GLOBAL}countries`,
        STATES: `${ApiEndpointService.GLOBAL}states/`,
        CITY: `${ApiEndpointService.GLOBAL}cities/`,
        PLATFORM_EVENTS: `${ApiEndpointService.EVENTS}upcoming`,
        PLATFORM_ALL_EVENTS: `${ApiEndpointService.EVENTS}`,
        EVENTS: `${ApiEndpointService.EVENTS}`,
        PUBLIC_EVENTS: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.EVENTS}`,
        BACKDOOR_LOGIN: `${ApiEndpointService.ADMIN_ACTIONS}backdoor-login`,
        VERIFY_EMAIL_TOKEN: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.AUTH_CONTEXT}verify/email-token/`,

        INVESTOR_CONNECTION_DOC_UPLOAD: `${ApiEndpointService.INVESTOR}upload/connection-document`,
        CONNECTION_DOC_UPLOAD: `${ApiEndpointService.CONNECTIONS}/upload/connection-document`,

        COMPLETE_HIRING_PROFILE : `${ApiEndpointService.JOBS}hiring-profile`,
        JOBS : `${ApiEndpointService.JOBS}`,
        IP_MANAGEMENT : `${ApiEndpointService.IP_MANAGEMENT}`,
        MATRICS : `${ApiEndpointService.MATRICS}`,
        MENTORSHIP : `${ApiEndpointService.MENTORSHIP}`,
        CHALLENGE : `${ApiEndpointService.CHALLENGES}`,
        CHALLENGE_PARTICIPANTS : `${ApiEndpointService.CHALLENGE_PARTICIPANTS}`,
        LOCATION_SEARCH : `${ApiEndpointService.GLOBAL}search/locations/`,
        GOVERMENT_GRANTS : `${ApiEndpointService.GLOBAL}government-grants`,
        SKILLS_SEARCH : `${ApiEndpointService.GLOBAL}search/skills/`,

        RECOMMENDED_INVESTOR: `${ApiEndpointService.STARTUP_CONTEXT}recommended/investors`,
        HIDE_RECOMMENDED_INVESTOR: `${ApiEndpointService.STARTUP_CONTEXT}recommended/hide-investor/`,
        RECOMMENDED_STARTUPS: `${ApiEndpointService.INVESTOR}recommended/startups`,
        RECOMMENDED_MENTOR_STARTUPS: `${ApiEndpointService.MENTORS}recommended/startups`,
        RECOMMENDED_MENTORS: `${ApiEndpointService.STARTUP_CONTEXT}recommended/mentors`,
        HIDE_RECOMMENDED_STARTUPS: `${ApiEndpointService.INVESTOR}recommended/hide-startup/`,

        JOBS_PUBLIC: `${ApiEndpointService.PUBLIC_CONTEXT}search/jobs`,
        JOBS_CHALLENGES: `${ApiEndpointService.PUBLIC_CONTEXT}search/challenges`,
        JOBS_PRIVATE_CHALLENGES: `challenges/search`,
        JOBS_PUBLIC_DETAILS: `${ApiEndpointService.PUBLIC_CONTEXT}jobs`,
        CHALLENGES_PUBLIC_DETAILS: `${ApiEndpointService.PUBLIC_CONTEXT}challenges`,

        RESUME: `${ApiEndpointService.PUBLIC_CONTEXT}resumes/`,
        VERIFY_TENANT: `${ApiEndpointService.GLOBAL}verify_tenant/`,

        FORMS_MANAGEMENT: `${ApiEndpointService.FORMS_MANAGEMENT}`,
        FORMS_MANAGEMENT_PUBLIC: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.FORMS_MANAGEMENT}`,

        POWER_PITCH: `${ApiEndpointService.POWER_PITCH}`,
        PROGRAMS_MANAGEMENT: `${ApiEndpointService.PROGRAMS_MANAGEMENT}`,
        VS_PROGRAMS_MANAGEMENT: `${ApiEndpointService.VS_PROGRAMS_MANAGEMENT}`,

        DELETE_ACCOUNT: `${ApiEndpointService.USERS}delete_account`,
        DEACTIVATE_ACCOUNT: `${ApiEndpointService.USERS}deactivate_account`,
        SUPPORTING_DOCUMENTS: `startup/documents/`,
        COMMUNITY_FEED: `${ApiEndpointService.COMMUNITY_FEED}`,
        COMMUNITY_FEED_PUBLIC: `${ApiEndpointService.PUBLIC_CONTEXT}${ApiEndpointService.COMMUNITY_FEED}`,


        USER_SEARCH_FILTER: `${ApiEndpointService.USER}search-filters`,

        MILESTONES : `milestones/`,
        TICKETS: `tickets/`,

        CONVERSATIONS: `${ApiEndpointService.CONVERSATIONS}`,
        CHAT:`${ApiEndpointService.CHAT}`,

        WHATSAPP_SEND_OTP: `${ApiEndpointService.PUBLIC_CONTEXT}otp_verifications/send/whatsapp`,
        WHATSAPP_VERIFY_OTP: `${ApiEndpointService.PUBLIC_CONTEXT}otp_verifications/verify/whatsapp`,
        AUTH_EXTERNAL: `${ApiEndpointService.PUBLIC_CONTEXT}auth-external`,



        INDIVUDIALS_INFORMATION: `${ApiEndpointService.INDIVIDUALS}information`,
        // ENGAGEMENT_INFO: `${ApiEndpointService.MENTORS}engagement-information`,
        INDIVIDUALS_PROFILE_COMPLETENESS: `${ApiEndpointService.INDIVIDUALS}profile_completeness`,
        INDIVIDUALS_LOGO_UPLOAD: `${ApiEndpointService.INDIVIDUALS}upload/logo`,
        // MENTOR_DASHBOARD_APPROVAL: `${ApiEndpointService.MENTORS}request/approval`,
        // MENTOR_PUBLIC_PROFILE: `${ApiEndpointService.MENTORS}public/mentor-information`,
        // MENTOR_DASHBOARD: `${ApiEndpointService.MENTORS}dashboard`,
        // MENTOR_SEARCH: `${ApiEndpointService.PUBLIC_CONTEXT}search/mentors`,
        // MENTOR_SEARCH_OPTIONS: `${ApiEndpointService.GLOBAL}custom/industries,domain_areas,technologies`,


        FACILITIES : `facility-management`,

    };

    /**
     * Constructor.
     */
    constructor(private http: HttpClient) {
    }

    public static setApiEndPoint(url: string = ""): void {
      ApiEndpointService.DOMAIN = url;
    }

    /**
     * Constructs an API endpoint.
     */
    public static getEndpoint(endpoint: string,v2 =false): string {
        const domain: string = ApiEndpointService.DOMAIN;
        const context: string = v2 ?ApiEndpointService.CONTEXT_v2 : ApiEndpointService.CONTEXT;
        return `${domain}${context}${endpoint}`;
    }

    /**
     * Determines if the requested URL is an authentication API endpoint.
     * @param {string} url
     * @returns {boolean}
     */
    public static isAuthEndpoint(url: string = ""): boolean {
        return url.toLowerCase().indexOf(ApiEndpointService.AUTH_CONTEXT) > -1;
    }

    public static isStartupKitEndpoint(url: string = ""): boolean {
      return url.toLowerCase().indexOf("startup-kit/service") > -1;
    }

    public static isPlatformFeedback(url: string = ""): boolean {
      return url.toLowerCase().indexOf("platform-feedback") > -1;
    }
    public static isWhatsappUrl(url: string = ""): boolean {
      return url.toLowerCase().indexOf("whatsapp") > -1;
    }

    /**
     * Determines if the requested URL is an API endpoint.
     * @param {string} url
     * @returns {boolean}
     */
    public static isApiEndpoint(url: string = ""): boolean {
        return url.toLowerCase().indexOf(ApiEndpointService.CONTEXT) > -1;
    }

    /**
     * Determines if the requested URL is an public page API endpoint.
     * @param {string} url
     * @returns {boolean}
     */
    public static isPublicPageEndpoint(url: string = ""): boolean {
        return url.toLowerCase().indexOf(ApiEndpointService.PUBLIC_CONTEXT) > -1;
    }
}
